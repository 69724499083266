import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'
import Section from '@components/Section'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Impressum"
        />
        <Divider />
          <Flex>
            <Box>
<h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
<p>Dipl.Psych. Ina Hild<br />
Psychologische Psychotherapeutin<br />
Hansastra&szlig;e 15<br />
24118 Kiel</p>

<h2>Kontakt</h2>
<p>Telefon: +49 431 8890175<br />
E-Mail: info@psychotherapie-hild.de</p>

<h2>Aufsichtsbeh&ouml;rde</h2>
<p>Psychotherapeutenkammer Schleswig-Holstein<br />
Sophienblatt 92-94<br />
24114 Kiel</p>
<p><a href="http://pksh.de" target="_blank" rel="noopener noreferrer">http://pksh.de</a></p>
<p>Kassenärtztliche Vereinigung Schleswig-Holstein<br />
Bismarckallee 1-6<br />
23795 Bad Segeberg</p>
<p><a href="http://kvsh.de" target="_blank" rel="noopener noreferrer">http://kvsh.de</a></p>

<h2>Berufsbezeichnung und berufsrechtliche Regelungen</h2>
<p>Berufsbezeichnung: gesetzl. Berufsbezeichnung: Psychologische Psychotherapeutin<br />
Approbation verliehen durch die Bezirksregierung Köln am 12.06.2009</p>
<p>Die Approbation berechtigt die Psychologische Psychotherapeutin zur Ausübung des psychotherapeutischen Berufes</p>
<p>Es gelten folgende berufsrechtliche Regelungen:</p>
<p>Berufsrechtliche Regelung:<br />
Gesetz über die Berufe des Psychologischen Psychotherapeuten und des Kinder- und Jugendlichenpsychotherapeuten (Psychotherapeutengesetz - PsychThG)
einsehbar unter: <a href="http://bptk.de" target="_blank" rel="noopener noreferrer">http://bptk.de</a></p>

<h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
<p><strong>Name und Sitz des Versicherers:</strong> <br/>
Gothaer Allgemeine Versicherung AG<br />
Gothaer Allee<br />
50969 Köln</p>
<p><strong>Geltungsraum der Versicherung:</strong><br />Deutschland</p>

<h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
<p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

<h2>Haftung für Links</h2>
<p>Mein Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte ich keinen Einfluss habe. Deshalb kann ich für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werde ich derartige Links umgehend entfernen.  </p>

<h2>Urheberrecht</h2>
<p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitte ich um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werde ich derartige Inhalte umgehend entfernen.  </p>
            </Box>
          </Flex>
      </Main>
    </Stack>
  </Layout>
)

export default PageContact
